import { Transition } from '@headlessui/react';
import React, { useRef, MouseEvent, ReactNode } from 'react';
import { Portal } from '~/components/interactive/Portals';
import CloseText from '~/components/interactive/CloseText';

export interface ModalProps {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
  lazy?: boolean;
}

let index = 50;

export function ModalWrapper({ children }: { children: ReactNode }) {
  return <div className="bg-white rounded-theme relative overflow-hidden m-6">{children}</div>;
}

export function ModalHeader({ children }: { children: ReactNode }) {
  return (
    <div className="p-inner text-sm border-theme-separator border-b flex items-center justify-between space-x-related">
      <div>{children}</div>
      <CloseText />
    </div>
  );
}

export default function Modal({ show, children, onClose, lazy }: ModalProps) {
  const ref = useRef<HTMLDivElement>(null);
  const closed = useRef(false);
  const animation = 'ease-in-out duration-[500ms]';

  return (
    <Portal>
      <Transition
        show={show}
        unmount={false}
        enter={animation}
        enterFrom="bg-opacity-0"
        enterTo="bg-opacity-50"
        leave={animation}
        leaveFrom="bg-opacity-50"
        leaveTo="bg-opacity-0"
        appear
        className="fixed inset-0 overflow-hidden bg-black modal flex items-center justify-center"
        style={{ zIndex: index++ }}
        afterLeave={() => (closed.current = false)}
        onClick={(e: MouseEvent) => {
          if (
            ref.current &&
            e.target instanceof HTMLElement &&
            ref.current.contains(e.target) &&
            !e.target.className.includes('close') &&
            !e.target.closest('.close')
          ) {
            return;
          }

          e.stopPropagation();

          if (closed.current) {
            return;
          }

          closed.current = true;

          onClose();
        }}
      >
        <Transition
          show={show}
          unmount={false}
          ref={ref}
          style={{ zIndex: index + 50 }}
          enter={animation}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={animation}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          appear
        >
          {lazy !== true || show ? children : <></>}
        </Transition>
      </Transition>
    </Portal>
  );
}
